/* eslint-disable */
import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';

const reactotron = Reactotron.configure({
  name: 'Wonking App',
  secure: false,
})
  .use(reactotronRedux())
  .use(trackGlobalErrors({ offline: false }))
  .use(sagaPlugin())
  .connect();

Reactotron.clear();
export default reactotron;
